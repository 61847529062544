import { Button, Container, Modal, Tab, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { addDays, format } from "date-fns";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import FlightSearchBox from "../FlightSearchBox/FlightSearchBox";
import FlightIcon from "@mui/icons-material/Flight";
import GroupsIcon from "@mui/icons-material/Groups";
import HotelIcon from "@mui/icons-material/Hotel";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import "./HomeSearchBox.css";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import secureLocalStorage from "react-secure-storage";
import License from "../License/License";
import VisaForm from "../VisaForm/VisaForm";
import HotelSearchBar from "../Hotel/HotelAfterSearch/HotelSearchBar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "50%",
  background: "var(--light-gray)",
  borderRadius: "20px",
  boxShadow: 24,
  px: { xs: 2, sm: 4 },
  py: 2,
};

const HomeSearchBox = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("user-info");
  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(null);
  const [changeState, setChangeState] = useState(null);
  //todo: End state for from date change
  const [type, setType] = React.useState("flight");
  const [fromSearchText, setFromSearchText] = useState(
    "Hazrat Shahjalal Intl Airport (DAC)"
  );
  const [toSearchText, setToSearchText] = useState("Cox's Bazar Airport(CXB)");

  const [departureDate, setDepartureDate] = useState(
    format(addDays(new Date(), 1), "dd MMM yy")
  );
  const [returningDate, setReturningDate] = useState(
    format(addDays(new Date(departureDate), 3), "dd MMM yy")
  );
  const [travelDate, setTravelDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 3),
      key: "selection",
    },
  ]);
  // for multiCity
  const now = useRef(new Date());
  const [value, setValue] = React.useState("oneway");
  const [className, setClassName] = useState("Y");
  const [from, setFrom] = useState(addDays(now.current, 1));
  const [to, setTo] = useState(addDays(now.current, 3));
  const [faddress, setfaddress] = useState("Dhaka,BANGLADESH");
  const [toAddress, setToAddress] = useState("Cox's Bazar,Bangladesh");
  const [fromSendData, setFromSendData] = useState("DAC");
  const [toSendData, setToSendData] = useState("CXB");
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infant, setInfant] = useState(0);
  const [result, setResult] = useState(1);
  const [directFlightOnly, setDirectFlightOnly] = useState(false);

  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };

  const [searchData, setSearchData] = useState({
    agentId: user?.user?.agentId || "",
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infant,
    connection: 1,
    cabinclass: className || "Y",
    segments: [
      {
        id: 0,
        openFrom: false,
        DepFrom: fromSendData.trim(),
        depFromText: fromSearchText.trim(),
        ArrTo: "DXB",
        arrToText: "Dubai Intl Airport [DXB]",
        openTo: false,
        Date: new Date().toLocaleDateString("sv"),
        openDate: false,
        open: false,
      },
    ],
  });

  const [options, setOptions] = useState({
    showCalendarAvailability: false,
    directFlightOnly: false,
    includeCheckedBaggage: false,
  });

  // ------------------------

  const [openLicense, setOpenLicense] = useState(false);
  const handleOpenLicense = () => setOpenLicense(true);
  const handleCloseLicense = () => setOpenLicense(false);

  const [openVisa, setOpenVisa] = useState(false);
  const handleOpenVisa = () => setOpenVisa(true);
  const handleCloseVisa = () => setOpenVisa(false);

  return (
    <Box>
      <Container>
        <Box
          sx={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            p: { xs: 1, sm: 2, md: 3 },
            my: "10px",
            borderRadius: "10px",
          }}
        >
          <FlightSearchBox
            options={options}
            setOptions={setOptions}
            value={value}
            setValue={setValue}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            departureDate={departureDate}
            setDepartureDate={setDepartureDate}
            returningDate={returningDate}
            setReturningDate={setReturningDate}
            travelDate={travelDate}
            setTravelDate={setTravelDate}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            faddress={faddress}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            setClassName={setClassName}
            changeFrom={changeFrom}
            setChangeState={setChangeState}
            changeState={changeState}
            setChangeFrom={setChangeFrom}
            directFlightOnly={directFlightOnly}
            setDirectFlightOnly={setDirectFlightOnly}
            searchData={searchData}
            setSearchData={setSearchData}
          />
        </Box>
      </Container>
      {/* //todo: Visa Modal */}

      {/* //todo: License Modal */}
    </Box>
  );
};

export default HomeSearchBox;
