import { Box, Container, Grid, Link, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import airplane from "../../images/landingPage/airplane.png";
import hotel from "../../images/landingPage/hotel.png";
import holiday from "../../images/landingPage/holiday.png";
import visa from "../../images/landingPage/visa.png";
import steeringWheel from "../../images/landingPage/steering-wheel.png";
import "./home.css";
import Header from "../../components/Header/Header";
import HomePageStack from "../../components/HomePageStack/HomePageStack";
import TravelCalender from "../../components/TravelCalender/TravelCalender";
import LiveQueues from "../../components/LiveQueues/LiveQueues";
import { useNavigate } from "react-router-dom";
import VisaForm from "../../components/VisaForm/VisaForm";
import License from "../../components/License/License";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  background: "var(--light-gray)",
  borderRadius: "20px",
  boxShadow: 24,
  px: 4,
  py: 2,
};

const Home = () => {
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(null);
  const [openLicense, setOpenLicense] = useState(false);
  const handleOpenLicense = () => setOpenLicense(true);
  const handleCloseLicense = () => setOpenLicense(false);

  const [openVisa, setOpenVisa] = useState(false);
  const handleOpenVisa = () => setOpenVisa(true);
  const handleCloseVisa = () => setOpenVisa(false);
  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 4, sm: 4, md: 2 } }}>
        <Box>
          <Grid container columnSpacing={5}>
            <Grid item xs={12} sm={12} md={4}>
              <Box>
                <HomePageStack />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  padding: "10px",
                  display: { xs: "flex", sm: "flex", md: "flex" },
                }}
              >
                <TravelCalender
                  totalData={totalData}
                  setTotalData={setTotalData}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <Box sx={{ display: "flex", gap: "40px", height: "fit-content" }}>
          <Box sx={{ width: "40%", height: "100%" }}>
         
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "10px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              padding: "10px",
              display: { xs: "none", sm: "none", md: "flex" },
            }}
          >
            <TravelCalender totalData={totalData} setTotalData={setTotalData} />
          </Box>
        </Box> */}
        <Box>
          <LiveQueues totalData={totalData} setTotalData={setTotalData} />
        </Box>
      </Container>
      {/* //todo: Visa Modal */}
      <Modal open={openVisa} onClose={handleCloseVisa}>
        <Box sx={style}>
          <VisaForm handleCloseVisa={handleCloseVisa} />
        </Box>
      </Modal>
      {/* //todo: License Modal */}
      <Modal open={openLicense} onClose={handleCloseLicense}>
        <Box sx={style}>
          <License handleCloseLicense={handleCloseLicense} />
        </Box>
      </Modal>
    </Box>
  );
};

export default Home;
